import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { H2, Medailon } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/fsivut/logofsi.png"
import Site from "../../../images/reference/fsivut/socsite.jpg"
import Site2 from "../../../images/reference/fsivut/socsite02.png"
import Katerina from "../../../images/reference/fsivut/katerina.jpg"
import University from "../../../images/reference/fsivut/brand-image.jpg"
import LongBanner from "../../../images/reference/fsivut/long_banner.jpg"
import MediaImage from "../../../images/reference/fsivut/media_image.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import LongReferenceImage from "../../../components/reference-detail/long-reference-image"

class Fsivut extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Náborové kampaně pro FSI VUT | igloonet.cz</title>
          <meta
            name="description"
            content="„igloonet je náš lety prověřený partner, na kterého se můžeme při realizaci online náborových kampaní 100% spolehnout,“
            Mgr. Kateřina Růžičková, FSI VUT."
          />
        </Helmet>

        <ReferenceHeader
          companyName="FSI VUT"
          heading="Dlouhodobá spolupráce na náborových kampaních studentů"
          date="jaro 2012 až současnost"
        />

        <ReferenceImageText
          right
          img={University}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo FSI VUT"
          link="//www.fme.vutbr.cz/"
        >
          <p>
            Fakulta strojního inženýrství se pyšní nejen titulem „nejvyšší
            vysoká” – její budova má 74 metrů –, ale také největším počtem
            studentů ze všech strojáren v republice – fakultu jich navštěvuje
            přes 4 200.
          </p>
          <p>
            Od roku 2012 se společně snažíme toto číslo zvyšovat, jak kampaněmi
            napříč Českem a Slovenskem, tak celosvětovými kampaněmi na nábor
            zahraničních studentů. Těší nás, že každý rok studium dokončí
            přibližně 1000 absolventů, kteří nemají problémy s uplatněním.
          </p>
        </ReferenceImageText>

        <ReferenceImageText right halfImgSize>
          <H2>Cíle spolupráce</H2>
          <ul>
            <li>
              Udržet a navýšit počet přihlášek i přes nepříznivé demografické
              vyhlídky
            </li>
            <li>
              Zvýšit povědomí o FSI jako přední české strojírenské fakultě mezi
              maturanty
            </li>
          </ul>
        </ReferenceImageText>

        <ReferenceImageText halfImgSize responsiveTextOrder img={Site2} alt="">
          <h2>Google a Seznam</h2>
          <p>
            Přestože jde primárně o e-commerce kanály, využíváme naplno sílu
            dvou hlavních vyhledávačů také při náborových kampaních. Cílíme v
            nich jak na konkrétní, brandové vyhledávací dotazy, tak přímo
            oborové. Vzhledem k techničtějšímu zaměření oborů volíme rozdílné
            bidovací strategie na muže a ženy a pohlaví cílové skupiny
            přizpůsobujeme i textace.
          </p>
          <p>
            Využíváme také reklamy v obsahové síti, kde se na relevantních
            webech snažíme získat pozornost naší cílovky. Součástí kampaně je
            také podpora offline aktivit fakulty souvisejících s akvizicí nových
            studentů, třeba Dnů otevřených dveří.
          </p>
        </ReferenceImageText>

        <ReferenceImageText right img={MediaImage} alt="">
          <h2>Sociální sítě</h2>
          <p>
            Hlavním kanálem pro zvýšení povědomí o Fakultě strojního inženýrství
            mezi maturanty je Facebook, v posledních letech doplněný o
            Instagram. Na základě demografických dat, kterými o uživatelích tyto
            sociální sítě disponují, dokážeme najít přesnou cílovou skupinu
            doplněnou také publiky podobnými dosavadním zájemcům a studentům.
            Potenciální uchazeče oslovujeme napříč zařízeními i reklamními
            platformami.
          </p>
          <p>
            Facebook využíváme také jako remarketingový nástroj. Pomocí měřících
            kódů umíme oslovit uchazeče v různých fázích procesu rozhodování až
            po podání samotné přihlášky. K remarketingu využíváme také
            dynamickou propagaci oborů skrze produktový katalog, který je běžný
            u e-commerce klientů. Zboží zde však nahradily studijní obory, které
            uživatelům ukazujeme pár dní po zobrazení webu.
          </p>
          <p>
            Narozdíl od Googlu a Seznamu, kteří člověka identifikuji na základě
            jeho cookies v prohlížeči, má Facebook tu výhodu, že rozpoznává
            napříč zařízeními. Dokáže konkrétního uživatele najít na mobilu,
            počítači i tabletu, a my tak doměříme třeba vliv reklamy na
            Instagramu, i když si student podá přihlášku přes Google.
          </p>
        </ReferenceImageText>

        <ReferenceImageText halfImgSize img={Site} responsiveTextOrder alt="">
          <h2>Analytika a návrh webu</h2>
          <p>
            Kampaň, jejíž zásah nedokážete změřit, je špatná kampaň. Správně
            nasazenou analytiku považujeme za polovinu úspěchu, proto (s pomocí
            rodu <a href="//houseofrezac.com/">House of Řezáč</a>), nasazujeme
            měřící kódy tak, abychom mohli remarketovat uživatele napříč
            univerzitními weby i v jednotlivých krocích přihláškového formuláře
            a segmentovat je do příslušných publik. Zároveň vylučujeme z kampaní
            stávající studenty i zaměstnance, které reklama na vlastní alma
            mater nemusí zrovna zajímat.
          </p>
          <p>
            Mluvit se snažíme i do podoby webu, podíleli jsme se na{" "}
            <a href="//fme.vutbr.cz/studuj/pojd/">návrhu stránek</a>, který poté
            fakulta sama realizovala.
          </p>
        </ReferenceImageText>

        <ReferenceImageText>
          <h2>Zahraniční kampaň</h2>
          <p>
            V roce 2017 jsme se popasovali s výzvou zvýšit počet přihlášek na
            zahraniční studijní programy fakulty. Hlavním kanálem pro seznámení
            evropských studentů s FSI se stal Facebook, který nám umožnil najít
            věkově i zájmově podobné uživatele stávajícím studentům.
          </p>
          <p>
            Vzhledem k omezenému rozpočtu jsme si nemohli dovolit zacílit na
            každý stát zvlášť, proto jsme se rozhodli rozdělit publika na západ,
            východ, sever a střed Evropy. Po krátkém testování jsme navíc
            vybrali TOP státy, mezi které jsme alokovali větší část rozpočtu.
          </p>
        </ReferenceImageText>

        <LongReferenceImage src={LongBanner} />

        <Medailon
          personName="Mgr. Kateřina Růžičková"
          position="marketingová manažerka"
          img={Katerina}
        >
          <p>
            igloonet je náš lety prověřený partner, na kterého se můžeme při
            realizaci online náborových kampaní 100% spolehnout. Dlouhodobá
            spolupráce umožňuje navázat na osvědčené prvky z přecházejících
            kampaní, ale igloonet každý rok přichází také s marketingovými
            novinkami. Kampaně hodnotíme velmi pozitivně, fakultě se daří udržet
            optimální počet studentů bakalářského studia.
          </p>
        </Medailon>

        <ReferenceImageText halfImgSize right>
          <H2>Shrnutí</H2>
          <p>
            Spolupráce s Fakultou strojního inženýrství je pro nás ukázkou
            ideálního dlouholetého partnerství. Každý rok implementujeme novinky
            a posouváme kampaně dál. I přes narůstající konkurenci
            (marketingovou i akademickou) se nám daří počet přihlášených
            studentů držet.
          </p>
        </ReferenceImageText>
        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Fsivut
